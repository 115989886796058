<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewL">
				<div class="search">
					<el-input v-model="keyword" @keyup.enter.native="toSearch" placeholder="请输入关键字"
						style="width: 150px;margin-right: 10px;">
					</el-input>
					<el-button type="primary" style="margin-right: 22px;" @click="toSearch">查询</el-button>
				</div>
				<div class="search">
					<div class="searchTitle">订单状态</div>
					<el-select v-model="orderState" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item, index) in stateList" :key="index" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">结算方式</div>
					<el-select v-model="orderPayType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item, index) in paymentList" :key="index" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">结算时间</div>
					<el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" @change="changeDate">
					</el-date-picker>
				</div>
				<div class="search" v-if="nickName">
					<el-tag closable @close="handleClose()">{{ nickName }}</el-tag>
				</div>
				<!-- <div class="search">
					<div class="searchTitle">使用时间</div>
					<el-select v-model="applyType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in [{label:'全部',value:''},{label:'猫',value:'1'},{label:'犬',value:'2'}]" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div> -->
			</div>
			<div class="tabViewR">
				<el-button @click="handleExport" v-if="$buttonAuthority('order-rechargeOrderList-export')">导出</el-button>
				<el-button @click="reset">重置</el-button>
				<el-button v-if="!$public.isNull($route.query.str)" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<!-- 内容信息 -->
		<div class="view">
			<el-table :data="tableData" height="100%">
				<el-table-column min-width="120" label="订单号">
					<template slot-scope="scope">
						{{ scope.row.orderNo }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="顾客">
					<template slot-scope="scope">
						{{ scope.row.userInfo.userName }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="手机号">
					<template slot-scope="scope">
						{{ scope.row.userInfo && scope.row.userInfo.userMobile }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="结算金额">
					<template slot-scope="scope">
						{{ scope.row.totalPrice }}
					</template>
				</el-table-column>
				<el-table-column min-width="80" label="订单状态">
					<template slot-scope="scope">
						{{ $public.getMatchingItem(stateList, 'value', scope.row.orderState).label }}
					</template>
				</el-table-column>
				<el-table-column min-width="120" label="结算方式">
					<template slot-scope="scope">
						<div v-if="!$public.isNull(scope.row.payInfo)">
							<div v-for="(item, index) in scope.row.payInfo.payList" :key="index">
								<span>
									{{ $public.getMatchingItem(paymentList, 'value', item.payType).label }}
									{{ item.money }}
								</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="订单备注">
					<template slot-scope="scope">
						{{ scope.row.remark }}
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="结算备注">
					<template slot-scope="scope">
						<div v-if="!$public.isNull(scope.row.payInfo)">
							{{ scope.row.payInfo.payRemark }}
						</div>
					</template>
				</el-table-column>
				<el-table-column min-width="110" label="创建人">
					<template slot-scope="scope">
						{{ scope.row.operationInfo.create.handleUserName }}
					</template>
				</el-table-column>
				<el-table-column min-width="140" label="销售日期">
					<template slot-scope="scope">
						{{ $public.FTime(scope.row.operationInfo.create.handleTimeStamp, "YYYY-MM-DD HH:mm") }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="small" v-if="$buttonAuthority('order-rechargeOrderList-see')"
							@click="$router.push({ path: '/order/rechargeOrderSee?no=' + scope.row.orderNo })">查看</el-button>
						<el-button type="text" size="small" v-if="$buttonAuthority('order-rechargeOrderList-print')"
							@click="print(scope.row)">打印小票</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="width:100%;position: absolute;bottom: 0;right: 0;">
				<page :pageIndex="page.pageIndex" :pageSizes="pageSizes" @size-change="handleSizeChange"
					:pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import printJs from "../../../util/print.js"
import page from "../../../components/page.vue"
export default {
	components: { page },
	data() {
		return {
			// 搜索
			keyword: '',
			orderState: -1,
			orderPayType: -1,
			nickName: '',
			time1: '',
			time2: '',
			// table
			page: {
				pageIndex: 1,
				pageSize: 10,
				total: 0,
			},
			pageSizes: [10, 15, 30, 60],
			userId: '',
			tableData: [],
			daterange: [],
		}
	},
	computed: {
		stateList() {
			let list = this.$public.getRechargeOrderStateList()
			list.unshift({ label: "全部", value: -1 })
			return list
		},
		paymentList() {
			let list = this.$public.getPaymentList()
			list.unshift({ label: "全部", value: -1 })
			return list
		},
	},
	mounted() {
		if (!this.$public.isNull(this.$route.query.str)) {
			this.userId = this.$route.query.str
			this.nickName = this.$route.query.nickName
			this.toSearch()
		}
		this.getTableData();
	},
	methods: {
		// 打印小票
		print(row) {
			printJs.printTicketSaleOrder(row).then(res => {
				this.$store.commit("set_printUrl", res)
				// this.$app.print(res)
				var hostObject = window.chrome.webview.hostObjects.customWebView2HostObject;
				hostObject.print(res);

			})


		},
		handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
		// 时间筛选
		changeDate(val) {
			if (val) {
				this.time1 = this.$public.FTimeStr10(val[0])
				this.time2 = this.$public.FTimeStr10(val[1])
				this.getTableData()
			}
		},
		// 执行搜索
		toSearch() {
			this.page.pageIndex = 1;
			this.getTableData()
		},
		handleClose() {
			this.keyword = ''
			this.userId = ''
			this.nickName = ''
			this.orderState = -1
			this.orderPayType = -1
			this.page.pageIndex = 1;
			this.daterange = [];
			this.getTableData()
		},
		reset() {
			this.daterange = [];
			this.keyword = ''
			this.orderState = -1
			this.orderPayType = -1
			this.page.pageIndex = 1;
			this.time1 = '';
			this.time2 = '';
			this.getTableData()
		},
		changePage(num) {
			this.page.pageIndex = num;
			this.getTableData()
		},
		getTableData() {
			let params = {
				"orderTypeId": [1],  // 订单类型 1. 余额充值 2 押金充值
				"userId": this.userId,  // 用户id
				"createStaffId": "",  // 创建人id
				"startTime": "",
				"endTime": "",
				"orderState": this.orderState === -1 ? [] : [this.orderState],  // 订单状态
				"orderPayType": this.orderPayType === -1 ? [] : [this.orderPayType],  // 支付方式
				"shopId": this.$store.state.userInfo.nowShop.id,  // 店铺编号
				"companyId": this.$store.state.userInfo.companyInfo.id,  // 公司编号
				"payStartTime": this.time1,  // 结算开始时间
				"payEndTime": this.time2,  // 结算结束时间
				"salesId": [],  // 销售人员id
				"keyword": this.keyword,
				"pageIndex": this.page.pageIndex,
				"pageSize": this.page.pageSize,
			}
			let loading = this.$loading()
			this.$http.post("/order/OrderRecharge/GetList", params).then(res => {
				loading.close()
				if (res.code === 0) {
					this.tableData = res.data.list
					this.page.total = res.data.count
				}
			})
		},
		// 导出
		handleExport() {
			let params = {
				keyWord: this.keyWord,
				userId: this.userId,
				payStartTime: this.time1,
				payEndTime: this.time2,
				shopId: this.$store.state.userInfo.nowShop.id,
				companyId: this.$store.state.userInfo.companyInfo.id,
				orderState: this.orderState === -1 ? [] : [this.orderState]
			}
			axios.get('/order/OrderRecharge/ExportList', {
				params,
				responseType: 'blob'
			}).then(response => {
				console.log("导出", response);
				var href = window.URL.createObjectURL(response.data); //创建下载的链接
				var downloadElement = document.createElement('a');
				downloadElement.href = href;
				downloadElement.download = '充值订单导出.xlsx';
				document.body.appendChild(downloadElement);
				downloadElement.click(); //点击下载
				document.body.removeChild(downloadElement); //下载完成移除元素
				window.URL.revokeObjectURL(href); //释放掉blob对象

			}).catch(err => {
				console.log(err)
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;

	// 搜索信息
	.tabView {
		height: 80px;
		border-radius: 20px;
		background: #fff;
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;

		.tabViewL {
			.search {
				margin-right: 12px;
				display: inline-flex;
				padding: 20px 0;

				.searchTitle {
					font-size: 14px;
					width: 60px;
					color: $fontColor;
					line-height: 40px;
				}
			}
		}

		.tabViewR {
			padding-top: 20px;
		}
	}

	// 内容信息
	.view {
		position: relative;
		height: calc(100% - 96px);
		padding-bottom: 52px;
		box-sizing: border-box;
		width: 100%;
		border-radius: 20px;
		overflow: hidden;
	}
}
</style>
